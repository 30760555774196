import React, { useState } from "react";
import CTAButton from "../../../../components/cta-button/cta-button";
import Filter from "../../../../components/filter/filter";
import TotalProfit from "./components/total-profit/total-profit";
import { Col, Row } from "react-bootstrap";
import TotalIncome from "./components/total-income/total-income";
import TotalExpenses from "./components/total-expenses/total-expenses";
import useIsMobile from "../../../../hooks/useIsMobile";

const IncomeExpensesPage: React.FC = () => {
    const isMobile = useIsMobile();

    // States
    const [selectedYear, setSelectedYear] = useState<string>("2025");

    // Functions
    const handleFilterClick = (year: string) => {
        if (selectedYear === year) {
            setSelectedYear("2025");
        } else {
            setSelectedYear(year);
        }
    };

    return (
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            <div className="d-flex align-items-center justify-content-between" style={{ marginTop: 42, marginBottom: 24 }}>
                <div className="d-flex" style={{ gap: 8 }}>
                    <Filter
                        label="2025"
                        isSelected={selectedYear === "2025"}
                        onSelect={() => handleFilterClick("2025")}
                    />
                    <Filter
                        label="2024"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                    />
                </div>
                <CTAButton
                    variant="primary"
                    size="S"
                    label="Exportieren"
                    icon="file_open"
                    onClick={() => alert("Coming soon...")}
                />
            </div>

            <TotalProfit totalProfit={selectedYear === "2025" ? 148000 : 120000} year={selectedYear} />

            <Row style={{ marginTop: 14 }}>
                <Col xs={12} lg={6}>
                    <TotalIncome totalIncome={selectedYear === "2025" ? 148000 : 120000} year={selectedYear} />
                </Col>
                <Col xs={12} lg={6} style={{marginTop: isMobile ? 14 : 0}}>
                    <TotalExpenses totalExpenses={selectedYear === "2025" ? 12000 : 8000} year={selectedYear} />
                </Col>
            </Row>
        </div>
    );
};

export default IncomeExpensesPage;
