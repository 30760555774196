import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const ListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue200};

  &:last-child {
    border-bottom: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
`;

export const ListItemDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 45%;
`;

export const ListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ListItemTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain};
`;

export const ListItemSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;

export const ListItemProperty = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  text-align: center;
  width: 30%;
  ${typeScale.bodyXS};
`;

export const ListItemAmount = styled.div`
  text-align: right;
  color: ${colorPalette.textMain};
  width: 15%;
  ${typeScale.bodyXSMain};

  &.positive {
    color: ${colorPalette.successMedium};
  }

  &.negative {
    color: ${colorPalette.errorDark};
  }
`;
