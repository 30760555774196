import React from "react";
import { EmptyStateContainer, Image, Title, Description } from "./empty-state.styles";

interface EmptyStateProps {
    imageSrc: string;
    imageWidth?: string;
    title: string;
    description: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ imageSrc, imageWidth, title, description }) => {
    return (
        <EmptyStateContainer>
            <Image src={imageSrc} width={imageWidth ?? "100%"} alt="Question Coin" />
            <Title>{title}</Title>
            <Description>{description}</Description>
        </EmptyStateContainer>
    );
};

export default EmptyState;
