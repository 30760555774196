import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const EmptyStateContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 16px;
  padding: 24px;
`;

export const Image = styled.img`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h3`
  margin-bottom: 8px;
  color: ${colorPalette.textDark};
  ${typeScale.headingXSMain};
`;

export const Description = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};
`;
