import { useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TabBar from "../../components/tab-bar/tab-bar";
import { EmptySubtitle, EmptyTitle, StyledContainer, Title, TabContentContainer } from "./financials-page.styles";
import { Col, Row } from "react-bootstrap";
import QuestionCoin from "../../assets/images/question-coin.png";
import FinanceUpsellingCard from "./components/finance-upselling-card";
import useUnitStore from "../../stores/unitStore";
import TransactionsPage from "./sub-pages/transactions-page/transactions-page";
import IncomeExpensesPage from "./sub-pages/income-expenses-page/income-expenses-page";
import OverviewPage from "./sub-pages/overview-page/overview-page";

const FinancialsPage = () => {
    // Stores
    const { units } = useUnitStore();

    // States
    const [activeTab, setActiveTab] = useState("overview");

    // Functions
    const renderTabContent = () => {
        switch (activeTab) {
            case "overview":
                return <OverviewPage key="overview" />;
            case "income_expenses":
                return <IncomeExpensesPage key="income_expenses" />;
            case "transactions":
                return <TransactionsPage key="transactions" />;
            default:
                return null;
        }
    };

    // Memos
    const showEmptyScreen = useMemo(() => {
        if (units.length === 0) return true;

        // TODO: Implement logic to check if one of the units has an active pm contract
        return units.filter((unit) => !unit.property.under_construction).length === 0;
    }, [units]);

    return (
        <StyledContainer>
            <Title>Finanzen</Title>

            {showEmptyScreen ? (
                <Row className="justify-content-center text-center" style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <Col xs={12} md={10} lg={8} xxl={6}>
                        <img src={QuestionCoin} width={175} alt="Question Coin" style={{ marginBottom: 24 }} />
                        <EmptyTitle>Keine Daten vorhanden</EmptyTitle>
                        <EmptySubtitle>Sobald deine Wohnungen vermietet sind, findest du alle finanziellen Daten und Dokumente hier.</EmptySubtitle>
                        <FinanceUpsellingCard />
                    </Col>
                </Row>
            ) : (
                <>
                    <TabBar
                        tabs={[
                            { label: "Übersicht", value: "overview" },
                            { label: "Einnahmen & Ausgaben", value: "income_expenses" },
                            { label: "Transaktionen", value: "transactions" },
                        ]}
                        activeTab={activeTab}
                        onTabChange={setActiveTab}
                    />

                    <TabContentContainer>
                        <TransitionGroup>
                            <CSSTransition
                                key={activeTab}
                                classNames="fade"
                                timeout={300}
                            >
                                {renderTabContent()}
                            </CSSTransition>
                        </TransitionGroup>
                    </TabContentContainer>
                </>
            )}
        </StyledContainer>
    );
};

export default FinancialsPage;
