import React, { useState } from "react";
import { Container, Content, EmptyContainer, EmptyTitle, HeaderContainer, Title } from "./income-and-expenses.styles";
import { Row, Col } from "react-bootstrap";
import CTAButton from "../../../../../../components/cta-button/cta-button";
import MaterialIcon from "../../../../../../components/material-icon";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { colorPalette } from "../../../../../../utils/theme";
import { ITransaction } from "../../../../components/transaction-table/transaction-table";
import { EmptySubtitle } from "../../../../financials-page.styles";
import Filter from "../../../../../../components/filter/filter";

/*const MOCK_TRANSACTIONS: ITransaction[] = [
    {
        id: 1,
        transactionType: "Auszahlung",
        property: "-",
        date: "10.16.2024",
        status: "Offen",
        amount: -10000,
        statusType: "open",
    },
    {
        id: 2,
        transactionType: "Mieteinnahmen 803",
        property: "803 • The Vybe",
        date: "10.01.2024",
        status: "Cheque Empfangen",
        amount: 20000,
        statusType: "received",
    },
    {
        id: 3,
        transactionType: "Instandhaltung",
        property: "803 • The Vybe",
        date: "06.27.2024",
        status: "Fällig",
        amount: -2000,
        statusType: "due",
    },
];*/

const MOCK_TRANSACTIONS: ITransaction[] = [];

const IncomeAndExpenses: React.FC = () => {
    const isMobile = useIsMobile();

    // States
    const [transactions, setTransactions] = useState<ITransaction[]>(MOCK_TRANSACTIONS);
    const [selectedYear, setSelectedYear] = useState<string>("2025");

    // Functions
    const handleFilterClick = (year: string) => {
        if (selectedYear === year) {
            setSelectedYear("2025");
        } else {
            setSelectedYear(year);
        }
    };

    return (
        <Container>
            <HeaderContainer>
                {isMobile ? (
                    <div className="d-flex align-items-center" style={{ gap: 4, cursor: "pointer" }} onClick={() => alert("Coming soon...")}>
                        <Title>Einnahmen & Ausgaben</Title>
                        {transactions.length > 0 && (
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        )}
                    </div>
                ) : (
                    <div className="d-flex align-items justify-content-between">
                        <Title style={{ cursor: "pointer" }} onClick={() => alert("Coming soon...")}>Einnahmen & Ausgaben</Title>
                        {transactions.length > 0 && (
                            <CTAButton
                                variant="ghost"
                                size="S"
                                label="Mehr anzeigen"
                                icon="arrow_outward"
                                onClick={() => alert("Coming soon...")}
                                noPadding
                            />
                        )}
                    </div>
                )}
                {transactions.length > 0 && (
                    <div className="d-flex" style={{ gap: 8, marginTop: 16 }}>
                        <Filter
                            label="2025"
                            isSelected={selectedYear === "2025"}
                            onSelect={() => handleFilterClick("2025")}
                        />
                        <Filter
                            label="2024"
                            isSelected={selectedYear === "2024"}
                            onSelect={() => handleFilterClick("2024")}
                        />
                    </div>
                )}
            </HeaderContainer>
            <Content>
                <Row>
                    <Col xs={12} lg={6}>
                        <EmptyContainer>
                            <EmptyTitle>-</EmptyTitle>
                            <EmptySubtitle>Gesamteinnahmen 2024</EmptySubtitle>
                        </EmptyContainer>
                    </Col>
                    <Col xs={12} lg={6} style={{ marginTop: isMobile ? 14 : 0 }}>
                        <EmptyContainer>
                            <EmptyTitle>-</EmptyTitle>
                            <EmptySubtitle>Gesamtausgaben 2024</EmptySubtitle>
                        </EmptyContainer>
                    </Col>
                </Row>
            </Content>
        </Container>
    );
};

export default IncomeAndExpenses;
