import React from "react";
import {
    Container,
    Header,
    ListItems,
    Subtitle,
    Title,
} from "./total-income.styles";
import { formatMoney } from "../../../../../../utils/helpers";
import TransactionItem from "../transaction-item/transaction-item";
import { colorPalette } from "../../../../../../utils/theme";

interface TotalIncomeProps {
    totalIncome: number;
    year: string;
}

const TotalIncome: React.FC<TotalIncomeProps> = ({ totalIncome, year }) => {
    const incomeData = [
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
        { title: "Mieteinnahmen", property: "803 • The Vybe", description: null, amount: 25000 },
    ];

    return (
        <Container>
            <Header>
                <Title>AED {formatMoney(totalIncome)}</Title>
                <Subtitle>Gesamteinnahmen {year}</Subtitle>
            </Header>

            <ListItems>
                {incomeData.map((income, index) => (
                    <TransactionItem
                        key={index}
                        title={income.title}
                        property={income.property}
                        description={income.description}
                        amount={income.amount}
                        icon="location_away"
                        iconColor={colorPalette.successMedium}
                    />
                ))}
            </ListItems>
        </Container>
    );
};

export default TotalIncome;
