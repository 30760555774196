import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const CardContainer = styled.div`
  margin-top: 24px;
  padding: 16px 0;
  border: 1px solid ${colorPalette.blue500};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  margin-bottom: 12px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};
`;

export const Subtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

export const Divider = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 1px;
  background-color: ${colorPalette.blue200};
`;

export const FeaturesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  gap: 32px;
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: start;
  gap: 16px;
`;

export const FeatureIcon = styled.div`
  color: ${colorPalette.blue400};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${colorPalette.blue300};
  flex-shrink: 0;
`;

export const FeatureTitle = styled.h3`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};
`;

export const FeatureDescription = styled.p`
  margin: 4px 0 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};
`;

export const ButtonsContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
`;
