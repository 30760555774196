import React, { useState } from "react";
import CTAButton from "../../../../components/cta-button/cta-button";
import Filter from "../../../../components/filter/filter";
import TransactionTable, { ITransaction } from "../../components/transaction-table/transaction-table";

const MOCK_TRANSACTIONS: ITransaction[] = [
    {
        id: 1,
        transactionType: "Auszahlung",
        property: "-",
        date: "10.16.2024",
        status: "Offen",
        amount: -10000,
        statusType: "open",
    },
    {
        id: 2,
        transactionType: "Mieteinnahmen 803",
        property: "803 • The Vybe",
        date: "10.01.2024",
        status: "Cheque Empfangen",
        amount: 20000,
        statusType: "received",
    },
    {
        id: 3,
        transactionType: "Instandhaltung",
        property: "803 • The Vybe",
        date: "06.27.2024",
        status: "Fällig",
        amount: -2000,
        statusType: "due",
    },
];

const TransactionsPage: React.FC = () => {
    // States
    const [selectedYear, setSelectedYear] = useState<string>("2025");

    // Functions
    const handleFilterClick = (year: string) => {
        if (selectedYear === year) {
            setSelectedYear("2025");
        } else {
            setSelectedYear(year);
        }
    };

    return (
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            <div className="d-flex align-items-center justify-content-between" style={{ marginTop: 42, marginBottom: 24 }}>
                <div className="d-flex" style={{ gap: 8 }}>
                    <Filter
                        label="Datum"
                        isSelected={false}
                        onSelect={() => handleFilterClick("2025")}
                    />
                    <Filter
                        label="Transaktion"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                        items={[
                            { label: "Alle", value: "all" },
                            { label: "Miete", value: "lease" },
                            { label: "Instandhaltung", value: "maintenance" },
                            { label: "Service Charges", value: "service_charge" }
                        ]}
                        hasDropdown
                        multiple
                    />
                    <Filter
                        label="Status"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                        items={[
                            { label: "Alle", value: "all" },
                            { label: "Offen", value: "open" },
                            { label: "Bezahlt", value: "paid" },
                            { label: "Fällig", value: "overdue" }
                        ]}
                        hasDropdown
                        multiple
                    />
                    <Filter
                        label="Wohnung"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                        items={[
                            { label: "Alle", value: "all" },
                            { label: "The Ivy", value: "1" },
                            { label: "The Vybe", value: "2" }
                        ]}
                        hasDropdown
                        multiple
                    />
                </div>
                <CTAButton
                    variant="primary"
                    size="S"
                    label="Exportieren"
                    icon="file_open"
                    onClick={() => alert("Coming soon...")}
                />
            </div>

            <TransactionTable transactions={MOCK_TRANSACTIONS} />
        </div>
    );
};

export default TransactionsPage;
