import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  padding: 0px 16px;
  margin-top: 42px;
  margin-bottom: 16px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  cursor: pointer;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Content = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;

export const EmptyContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
`;

export const EmptyTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.successMedium};
  ${typeScale.headingMain}
`;

export const EmptySubitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}
`;
