import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";
import BackgroundImage from "../../assets/images/home-bg.png";
import LogoBackgroundImage from "../../assets/images/home-logo-bg.png";
import AppSectionImage from "../../assets/images/landing-page/app_section_shadow.png";

export const Container = styled.div`
  padding-bottom: 32px;
`;

// Hero Section
export const HeroSection = styled.section`
  position: relative;
  background: url(${LogoBackgroundImage}) no-repeat center top,
    url(${BackgroundImage}) no-repeat center center,
    linear-gradient(180deg, rgba(255, 255, 255, 0) 8.13%, #ffffff 100%);
  background-size: cover, cover, cover;
  margin: 0;
  margin-top: -65.8px;
  padding-top: 120px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 120px;

  /* Pseudo-element for fade-out effect */
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
    pointer-events: none;
  }

  @media (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 60px;
  background-color: ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-top: 16px;
  }
`;

export const HeroContent = styled.div`
  p {
    margin-bottom: 14px;
    color: ${colorPalette.blue300};
    ${typeScale.bodyMDMain}
  }
`;

export const HeroTitle = styled.h1`
  margin-bottom: 42px;
  color: ${colorPalette.white};
  font-family: "Funnel Display", sans-serif;
  font-size: 4.225rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.03em;

  @media (max-width: 767.98px) {
    margin-bottom: 8px;
    font-size: 2.125rem;
    line-height: 1.25;
  }
`;

export const HeroSubtitle = styled.p`
  margin-bottom: 42px !important;
  color: ${colorPalette.white} !important;
  ${typeScale.bodyLG};

  @media (max-width: 767.98px) {
    margin-bottom: 24px !important;
    color: ${colorPalette.textLight};
    ${typeScale.bodyXS};
  }
`;

export const HeroButtonWrapper = styled.div`
  width: 270px;

  @media (max-width: 767.98px) {
    width: 200px;
  }
`;

export const GlassBuilding = styled.img`
  width: 100%;
  max-width: 365px;

  @media (max-width: 767.98px) {
    width: 200px;
  }
`;

// Features Section
export const FeatureSection = styled.section`
  margin-left: 70px;
  margin-right: 70px;
  border-bottom: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const FeatureButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;

  @media (max-width: 767.98px) {
    width: 200px;
    justify-self: center;
  }
`;

export const SectionBadge = styled.p`
  margin-bottom: 4px;
  text-transform: uppercase;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain};

  @media (max-width: 767.98px) {
    margin-bottom: 8px;
    ${typeScale.bodyXXSMain};
  }
`;

export const SectionTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-bottom: 12px;
    ${typeScale.headingSMMain};
  }
`;

export const SectionSubtitle = styled.h2`
  margin-bottom: 32px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG};

  @media (max-width: 767.98px) {
    margin-bottom: 24px;
    ${typeScale.bodyXS};
  }
`;

export const FeatureCardsWrapper = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  margin-bottom: 24px;
  margin-left: -16px; /* Offset the section's padding */
  margin-right: -16px; /* Offset the section's padding */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FeatureCard = styled.div`
  height: 100%;
  flex: 1;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${colorPalette.blue200};
  text-align: center;

  @media (max-width: 767.98px) {
    height: auto;
    flex: 0 0 auto;
    width: 85%;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 16px;
    }
  }
`;

export const FeatureIcon = styled.img`
  width: 114px;
  margin-bottom: 16px;
`;

export const FeatureText = styled.div`
  text-align: start;

  h4 {
    margin-bottom: 16px;
    color: ${colorPalette.textMain};
    ${typeScale.headingXSMain};
  }

  p {
    margin: 0;
    color: ${colorPalette.textDark};
    ${typeScale.bodyXS};
  }
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: start;
`;

export const FeatureListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;

export const FeatureListItemLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

// App Section
export const AppSection = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  margin-left: 70px;
  margin-right: 70px;
  border-bottom: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const AppSectionBackground = styled.div`
  position: relative;
  margin-left: -16px;
  margin-right: -16px;
  overflow: hidden;
  background: url(${AppSectionImage}) no-repeat center;
  background-size: contain;
  backdrop-filter: blur(48.417720794677734px);
`;

export const AppCardsWrapper = styled.div<{ activeIndex: number }>`
  display: flex;
  gap: 16px;
  background: url(${AppSectionImage}) no-repeat center;
  background-size: contain;
  background-position: -16px center;
  backdrop-filter: blur(48.417720794677734px);
  transition: transform 0.3s ease-in-out;
  transform: ${({ activeIndex }) =>
    `translateX(calc(-${activeIndex} * (100% - 40px)))`};

  @media (max-width: 767.98px) {
    gap: 8px;
  }
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const IndicatorDot = styled.div<{ isActive?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 4px;
  background-color: ${({ isActive }) =>
    isActive ? colorPalette.blue500 : "#17224D33"};
  transition: background-color 0.3s ease;
  cursor: pointer;
`;

export const AppCard = styled.div`
  height: 100%;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  text-align: center;
  background: #ffffffb2;
  position: relative;
  overflow: hidden;

  @media (max-width: 767.98px) {
    height: auto;
    flex: 0 0 auto;
    width: 90%;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 16px;
    }
  }
`;

export const AppScreen = styled.img`
  width: 300px;
  filter: drop-shadow(0px 3.52px 17.61px rgba(0, 0, 0, 0.4));

  @media (max-width: 767.98px) {
    width: 250px;
    filter: drop-shadow(0px 2.88px 14.38px 0px rgba(0, 0, 0, 0.4));
  }
`;

export const AppScreenBackground = styled.div`
  background: url(${AppSectionImage}) no-repeat center;
  background-size: contain;
`;

export const AppOverlay = styled.div`
  text-align: start;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 16px;
  backdrop-filter: blur(10px);
`;

export const AppTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const AppDescription = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;

export const ClickableList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 48px;
`;

export const ClickableListItem = styled.li<{ isActive: boolean }>`
  padding: 24px 0;
  cursor: pointer;
  border-bottom: 1px solid ${colorPalette.blue200};
  color: ${(props) =>
    props.isActive ? colorPalette.blue500 : colorPalette.textMain};
  transition: color 0.3s ease;

  &:hover {
    color: ${colorPalette.blue500};
  }
`;

export const ItemTitle = styled.h4`
  margin-bottom: 8px;
  ${typeScale.headingSMMain}
`;

export const ItemDescription = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  font-size: ${typeScale.bodySM};
`;

export const AppButtonWrapper = styled.div`
  width: 270px;
  margin-top: 36px;

  @media (max-width: 767.98px) {
    width: 200px;
    margin-top: 8px;
    justify-self: center;
    margin-bottom: 24px;
  }
`;

export const StatsSection = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  margin-left: 70px;
  margin-right: 70px;
  background-color: ${colorPalette.white};
  //border-bottom: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const StatsTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-bottom: 12px;
    ${typeScale.headingSMMain};
  }
`;

export const StatsSubtitle = styled.p`
  margin-bottom: 32px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;

export const StatCard = styled.div`
  text-align: end;
  margin-bottom: 24px;

  @media (max-width: 767.98px) {
    text-align: start;
  }
`;

export const StatNumber = styled.h3`
  margin-bottom: 8px;
  color: ${colorPalette.blue400};
  ${typeScale.headingXLMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingLMain};
  }
`;

export const StatLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;

export const StatsButtonWrapper = styled.div`
  width: 180px;
  margin-top: 24px;

  @media (max-width: 767.98px) {
    width: 200px;
    margin-top: 8px;
    justify-self: center;
    margin-bottom: 24px;
  }
`;
