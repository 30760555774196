import React, { useState } from "react";
import { Container, Content, HeaderContainer, Title } from "./last-transactions.styles";
import QuestionCoin from "../../../../../../assets/images/question-coin.png";
import CTAButton from "../../../../../../components/cta-button/cta-button";
import MaterialIcon from "../../../../../../components/material-icon";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { colorPalette } from "../../../../../../utils/theme";
import EmptyState from "../../../../components/empty-state/empty-state";
import TransactionTable, { ITransaction } from "../../../../components/transaction-table/transaction-table";
import Banner from "../banner/banner";

/*const MOCK_TRANSACTIONS: ITransaction[] = [
    {
        id: 1,
        transactionType: "Auszahlung",
        property: "-",
        date: "10.16.2024",
        status: "Offen",
        amount: -10000,
        statusType: "open",
    },
    {
        id: 2,
        transactionType: "Mieteinnahmen 803",
        property: "803 • The Vybe",
        date: "10.01.2024",
        status: "Cheque Empfangen",
        amount: 20000,
        statusType: "received",
    },
    {
        id: 3,
        transactionType: "Instandhaltung",
        property: "803 • The Vybe",
        date: "06.27.2024",
        status: "Fällig",
        amount: -2000,
        statusType: "due",
    },
];*/

const MOCK_TRANSACTIONS: ITransaction[] = [];

const LastTransactions: React.FC = () => {
    const isMobile = useIsMobile();

    // States
    const [transactions, setTransactions] = useState<ITransaction[]>(MOCK_TRANSACTIONS);

    return (
        <Container>
            <HeaderContainer>
                {isMobile ? (
                    <div className="d-flex align-items-center" style={{ gap: 4, cursor: "pointer" }} onClick={() => alert("Coming soon...")}>
                        <Title>Letzte Transaktionen</Title>
                        {transactions.length > 0 && (
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        )}
                    </div>
                ) : (
                    <>
                        <Title style={{ cursor: "pointer" }} onClick={() => alert("Coming soon...")}>Letzte Transaktionen</Title>
                        {transactions.length > 0 && (
                            <CTAButton
                                variant="ghost"
                                size="S"
                                label="Alle Transaktionen"
                                icon="arrow_outward"
                                onClick={() => alert("Coming soon...")}
                                noPadding
                            />
                        )}
                    </>
                )}
            </HeaderContainer>
            <Content>
                {transactions.length > 0 ? (
                    <>
                        <TransactionTable transactions={MOCK_TRANSACTIONS} />
                        <Banner />
                    </>
                ) : (
                    <EmptyState
                        imageSrc={QuestionCoin}
                        imageWidth="175px"
                        title="Keine Transaktionen"
                        description="Sobald deine Wohnungen vermietet sind, findest du alle Transaktionen hier."
                    />
                )}
            </Content>
        </Container>
    );
};

export default LastTransactions;
