import React from "react";
import {
    Container,
    Header,
    ListItems,
    Subtitle,
    Title,
} from "./total-expenses.styles";
import { formatMoney } from "../../../../../../utils/helpers";
import TransactionItem from "../transaction-item/transaction-item";
import { colorPalette } from "../../../../../../utils/theme";

interface TotalExpensesProps {
    totalExpenses: number;
    year: string;
}

const TotalExpenses: React.FC<TotalExpensesProps> = ({ totalExpenses, year }) => {
    const incomeData = [
        { title: "Instandhaltung", property: "803 • The Vybe", description: "Reparatur Klimaanlage", amount: -2000 },
        { title: "Service Charges 2024", property: "803 • The Vybe", description: "2024", amount: -8000 },
        { title: "Service Charges 2024", property: "1704 • The Ivy", description: "2024", amount: -8000 },
    ];

    return (
        <Container>
            <Header>
                <Title>AED {formatMoney(totalExpenses)}</Title>
                <Subtitle>Gesamtausgaben {year}</Subtitle>
            </Header>

            <ListItems>
                {incomeData.map((expense, index) => (
                    <TransactionItem
                        key={index}
                        title={expense.title}
                        property={expense.property}
                        description={expense.description}
                        amount={expense.amount}
                        icon="reset_wrench"
                        iconColor={colorPalette.errorDark}
                    />
                ))}
            </ListItems>
        </Container>
    );
};

export default TotalExpenses;
