import React, { useCallback, useMemo, useRef, useState } from 'react';
import { HeaderContainer, WelcomeMessage, HeaderTitle, Headline } from './header.styles';
import PortfolioChartCard, { PortfolioChartCardRef } from '../portfolio-chart-card/portfolio-chart-card';
import { Col, Row } from 'react-bootstrap';
import RentalPaymentsCard from '../rental-payments-card/rental-payments-card';
//import HandoverBanner from '../handover-banner/handover-banner';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import MobileRentalPaymentsCard from '../mobile-rental-payments-card/mobile-rental-payments-card';
import MobileUnitsRentedCard from '../mobile-units-rented-card/mobile-units-rented-card';
import UnitsRentedStatisticCard from '../units-rented-statistic-card/units-rented-statistic-card';
import { IDashboard } from '../../../../services/dashboardService';
import useIsMobile from '../../../../hooks/useIsMobile';
import OnboardingChecklistCard from '../onboarding-checklist-card/onboarding-checklist-card';
import { DocumentType } from '../../../../services/documentService';
import EmptyRentalStatsCard from '../empty-rental-stats-card/empty-rental-stats-card';
import MobileRentPaymentsCard from '../mobile-rent-payments-card/mobile-rent-payments-card';
import MobileTotalUnitsCard from '../mobile-total-units-card/mobile-total-units-card';
import { colorPalette } from '../../../../utils/theme';

interface HeaderProps {
    dashboard: IDashboard;
    totalOffPlanUnits: number;
    totalRentableUnits: number;
    totalRentedUnits: number;
    totalUnits: number;
    grossYield: number;
}

const Header: React.FC<HeaderProps> = ({ dashboard, totalOffPlanUnits, totalRentableUnits, totalRentedUnits, totalUnits, grossYield }) => {
    const isMobile = useIsMobile();

    // References
    const startXRef = useRef(0);
    const chartRef = useRef<PortfolioChartCardRef>(null);

    // States
    const [currentIndex, setCurrentIndex] = useState(0);

    // Stores
    const investor = useInvestorDetailStore(state => state.investor);

    let years: number[] = [];
    let volumeData: number[] = [];

    if (dashboard) {
        years = Object.keys(dashboard.purchase_values_by_year).map(Number);

        const firstYear = Math.min(...years);
        const startYear = firstYear - 1;

        const endYear = new Date().getFullYear();
        years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

        volumeData = years.map(year => dashboard.purchase_values_by_year[year] || 0);
        volumeData[years.indexOf(firstYear)] = dashboard.purchase_values_by_year[firstYear] || 0;
    }

    // Memos
    const hasAddress = useMemo(() => {
        if (!investor) return false;
        return investor.address.country !== null || investor.address.country !== undefined;
    }, [investor]);

    const hasMandatoryDocumentsUploaded = useMemo(() => {
        if (!investor) return false;
        return investor.documents.filter(document => document.document_type === DocumentType.PASSPORT || document.document_type === DocumentType.PROOF_OF_ADDRESS).length > 1
    }, [investor]);

    const hasBankaccount = useMemo(() => {
        if (!investor) return false;
        return investor.bank_details.length > 0;
    }, [investor]);


    // Functions
    const handleSwipe = useCallback((direction: 'left' | 'right') => {
        const totalCards = 3;
        if (direction === 'left') {
            setCurrentIndex((prev) => (prev + 1) % totalCards);
        } else if (direction === 'right') {
            setCurrentIndex((prev) => (prev - 1 + totalCards) % totalCards);
        }

        if (chartRef.current) {
            chartRef.current.hideTooltip();
        }
    }, [chartRef]);

    const handleTouchStart = (e: React.TouchEvent) => {
        startXRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        const endX = e.changedTouches[0].clientX;
        const difference = startXRef.current - endX;

        if (difference > 50) handleSwipe('left');
        if (difference < -50) handleSwipe('right');
    };

    return (
        <HeaderContainer>
            <WelcomeMessage>Hi, {investor?.first_name}</WelcomeMessage>
            <HeaderTitle>Willkommen zurück.</HeaderTitle>
            {/* 
            TODO: Handle Visibility
            <HandoverBanner />
            */}
            {!isMobile && (
                <Headline>Portfolioübersicht</Headline>
            )}
            <Row className={`d-flex align-items-stretch gy-3 ${isMobile ?? "gx-2 h-100"}`} style={{ marginTop: 0 }}>
                <Col sm={12} md={6} className="d-flex align-items-stretch">
                    {isMobile ? (
                        <div
                            className="h-100"
                            style={{ position: 'relative', width: '100%', overflow: 'hidden' }}
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    transform: `translateX(-${currentIndex * 100}%)`,
                                    transition: 'transform 0.2s ease-in-out',
                                }}
                            >
                                {/* Card 1 */}
                                <div style={{ flex: '0 0 100%' }}>
                                    <PortfolioChartCard
                                        ref={chartRef}
                                        portfolioVolume={dashboard.portfolio_value}
                                        grossYield={grossYield}
                                        years={years}
                                        volumeData={volumeData}
                                    />
                                </div>
                                {/* Card 2 */}
                                <div style={{ flex: '0 0 100%' }}>
                                    <MobileTotalUnitsCard
                                        offPlanUnits={totalOffPlanUnits}
                                        completedUnits={totalRentableUnits}
                                        rentedUnits={totalRentedUnits}
                                        totalUnits={totalUnits}
                                    />
                                </div>
                                {/* Card 3 */}
                                <div style={{ flex: '0 0 100%' }}>
                                    <MobileRentPaymentsCard
                                        annualRent={190000}
                                        grossReturn={(190000 / 1300000) * 100}
                                    />
                                </div>
                            </div>

                            {/* Indicators */}
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: 6,
                                            height: 6,
                                            margin: '0 4px',
                                            borderRadius: '50%',
                                            backgroundColor: currentIndex === index ? colorPalette.white : 'rgba(255,255,255,0.5)',
                                            transition: 'background-color 0.3s ease-in-out',
                                            cursor: currentIndex !== index ? "pointer" : "auto"
                                        }}
                                        onClick={() => setCurrentIndex(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <PortfolioChartCard
                            portfolioVolume={dashboard.portfolio_value}
                            grossYield={grossYield}
                            years={years}
                            volumeData={volumeData}
                        />
                    )}
                </Col>

                {(investor && (!hasAddress || !hasBankaccount || !hasMandatoryDocumentsUploaded)) ? (
                    <Col sm={12} md={6} className="d-flex align-items-stretch">
                        <OnboardingChecklistCard
                            hasAddress={hasAddress}
                            hasDocumentsUploaded={hasMandatoryDocumentsUploaded}
                            hasBankaccount={hasBankaccount}
                        />
                    </Col>
                ) : (
                    <>
                        {totalRentedUnits === 0 ? (
                            <Col sm={12} md={6} className="d-flex align-items-stretch">
                                <EmptyRentalStatsCard />
                            </Col>
                        ) : (
                            <>
                                {isMobile ? (
                                    <>
                                        <Col xs={6} className="d-flex align-items-stretch">
                                            <MobileUnitsRentedCard
                                                totalUnits={totalRentableUnits}
                                                unitsRented={totalRentedUnits}
                                            />
                                        </Col>
                                        <Col xs={6} className="d-flex align-items-stretch">
                                            <MobileRentalPaymentsCard
                                                totalPayments={dashboard.total_payments}
                                                receivedPayments={dashboard.received_payments}
                                            />
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col sm={6} md={3} className="d-flex align-items-stretch">
                                            <UnitsRentedStatisticCard
                                                totalUnits={totalRentableUnits}
                                                unitsRented={totalRentedUnits}
                                            />
                                        </Col>
                                        <Col sm={6} md={3} className="d-flex align-items-stretch">
                                            <RentalPaymentsCard
                                                totalPayments={dashboard.total_payments}
                                                receivedPayments={dashboard.received_payments}
                                            />
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Row>
        </HeaderContainer>
    );
};

export default Header;
