import React from "react";
import GoodToKnowArticles from "../../../../components/good-to-know-articles/good-to-know-articles";
import { Col, Row } from "react-bootstrap";
import EmptyState from "../../components/empty-state/empty-state";
import useIsMobile from "../../../../hooks/useIsMobile";
import CashCoin from "../../../../assets/images/cash_coin.svg";
import UmbrellaCoin from "../../../../assets/images/umbrella_coin.svg";
import IncomeAndExpenses from "./components/income-and-expenses/income-and-expenses";
import LastTransactions from "./components/last-transactions/last-transactions";
import NextRentPaymentCard from "./components/next-rent-payment-card/next-rent-payment-card";
import { ILeasePayment, PaymentMethod, PaymentPurpose } from "../../../../services/leaseService";
import AnnualProfitCard from "./components/annual-profit-card/annual-profit-card";

const mockLeasePayment: ILeasePayment = {
    payment_id: 1,
    lease_id: 123,
    due_date: "2024-12-01", // ISO 8601 date format
    amount: 15000, // Mock amount in AED
    cheque_no: "123456789", // Example cheque number
    bank_name: "Emirates NBD", // Example bank name
    remark: "December rent", // Example remark
    method: PaymentMethod.CHEQUE, // Example payment method
    purpose: PaymentPurpose.RENT, // Example payment purpose
    bounced: false, // Payment has not bounced
    payment_date: null, // Payment not yet made
    document: null, // No document associated
    created_at: "2024-11-01T12:00:00Z", // ISO 8601 timestamp
    updated_at: "2024-11-15T12:00:00Z", // ISO 8601 timestamp
};

const OverviewPage: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <>
            <Row style={{ marginTop: 42, paddingLeft: 12, paddingRight: 12 }}>
                <Col xs={12} lg={6}>
                    <>
                        <NextRentPaymentCard
                            payment={mockLeasePayment}
                            onViewAll={() => console.log('View all transactions')}
                        />
                        {/*<EmptyState
                            imageSrc={CashCoin}
                            imageWidth="130px"
                            title="Nächste Mietauszahlung"
                            description="Sobald deine erste Mietzahlung bereit ist, an dich ausgezahlt zu werden, findest du alle Details hier."
                        />*/}
                    </>
                </Col>
                <Col xs={12} lg={6} style={{ marginTop: isMobile ? 14 : 0 }}>
                    <>
                        <AnnualProfitCard
                            payment={mockLeasePayment}
                            onViewAll={() => console.log('View all transactions')}
                        />
                        {/*<EmptyState
                        imageSrc={UmbrellaCoin}
                        imageWidth="116px"
                        title="Jahresgewinn"
                        description="Sobald deine Wohnungen vermietet sind, findest du alle Einnahmen und Ausgaben hier."
                    />*/}
                    </>
                </Col>
            </Row>

            <IncomeAndExpenses />

            <LastTransactions />

            <GoodToKnowArticles />
        </>
    );
};

export default OverviewPage;
