import React from "react";
import {
    ListItemContainer,
    IconContainer,
    ListItemDetails,
    ListItemTextContainer,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAmount,
    ListItemProperty,
} from "./transaction-item.styles";
import MaterialIcon from "../../../../../../components/material-icon";
import { formatMoney } from "../../../../../../utils/helpers";
import { MaterialSymbol } from "material-symbols";

interface TransactionItemProps {
    title: string;
    property?: string;
    description?: string | null;
    amount: number;
    icon: MaterialSymbol;
    iconColor: string;
}

const TransactionItem: React.FC<TransactionItemProps> = ({ title, property, description, amount, icon, iconColor }) => {
    return (
        <ListItemContainer>
            <ListItemDetails>
                <IconContainer>
                    <MaterialIcon icon={icon} size={20} color={iconColor} />
                </IconContainer>
                <ListItemTextContainer>
                    <ListItemTitle>{title}</ListItemTitle>
                    {description && <ListItemSubtitle>{description}</ListItemSubtitle>}
                </ListItemTextContainer>
            </ListItemDetails>
            {property && <ListItemProperty>{property}</ListItemProperty>}
            <ListItemAmount className={amount > 0 ? "positive" : "negative"}>
                {formatMoney(amount)} AED
            </ListItemAmount>
        </ListItemContainer>
    );
};

export default TransactionItem;
