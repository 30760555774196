import React, { useEffect, useState } from "react";
import {
    TabBarContainer,
    LogoContainer,
    TabList,
    Tab,
    ComingSoonBadge,
    LoginButton,
} from "./tab-bar.styles";
import Logo from "../../../../assets/images/logo/logo-light.svg";
import useCustomNavigate from "../../../../hooks/useCustomNavigate";
import { colorPalette } from "../../../../utils/theme";
import useIsMobile from "../../../../hooks/useIsMobile";
import MaterialIcon from "../../../../components/material-icon";
import { useLocation } from "react-router-dom";
import useInvestorDetailStore from "../../../../stores/investorDetailStore";
import Sidebar from "../sidebar/sidebar";

interface TabBarProps {
    onClickItem: (value: string) => void;
}

const TabBar: React.FC<TabBarProps> = ({ onClickItem }) => {
    const navigate = useCustomNavigate();
    const location = useLocation();
    const isMobile = useIsMobile();

    // Stores
    const { investor } = useInvestorDetailStore();

    // States
    const [isScrolled, setIsScrolled] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    useEffect(() => {
        const scrollContainer = document.querySelector("body");

        const handleScroll = () => {
            if ((scrollContainer?.scrollTop || 0) > 20) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        scrollContainer?.addEventListener("scroll", handleScroll);
        
        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <TabBarContainer className={isScrolled ? "scrolled" : ""}>
                <TabList>
                    <LogoContainer>
                        <img src={Logo} alt="Managd Logo" />
                    </LogoContainer>
                    {!isMobile && (
                        <>
                            <Tab showBottomBorder>Vermieter</Tab>
                            <div className="d-flex align-items-center">
                                <Tab style={{ color: colorPalette.blue300 }}>Mieter</Tab>
                                <ComingSoonBadge>Coming<br></br>Soon</ComingSoonBadge>
                            </div>
                        </>
                    )}
                </TabList>
                <TabList>
                    {isMobile ? (
                        <>
                            {!location.pathname.includes("/profile") && investor && (
                                <MaterialIcon icon="account_circle" color={colorPalette.white} size={24} onClick={() => navigate("/profile", { replace: true, clearHistory: true })} />
                            )}
                            <MaterialIcon icon="menu" color={colorPalette.white} size={28} onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
                        </>
                    ) : (
                        <>
                            <Tab onClick={() => onClickItem("services")}>Leistungen</Tab>
                            <Tab onClick={() => onClickItem("packages")}>Pakete</Tab>
                            <Tab onClick={() => onClickItem("faq")}>FAQ</Tab>
                            <LoginButton onClick={() => navigate("/login")}>Anmelden</LoginButton>
                        </>
                    )}
                </TabList>
            </TabBarContainer>

            {isMobile && (
                <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={() => setIsSidebarVisible(!isSidebarVisible)} />
            )}
        </>
    );
};

export default TabBar;
