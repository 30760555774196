import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const TabBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: transparent;
  border-bottom: 1px solid ${colorPalette.blue300};
  color: ${colorPalette.white};
  transition: background-color 0.3s ease;

  /* Add a solid background when scrolled */
  &.scrolled {
    background: ${colorPalette.blue800};
  }
`;

export const LogoContainer = styled.div`
  img {
    width: 120px;
  }
`;

export const TabList = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Tab = styled.p<{ showBottomBorder?: boolean }>`
  margin: 0;
  position: relative;
  color: ${colorPalette.white};
  ${typeScale.bodyLGMain};
  cursor: pointer;

  &:hover {
    color: ${colorPalette.blue300};
  }

  ${({ showBottomBorder }) =>
    showBottomBorder &&
    `
        &:after {
            content: "";
            position: absolute;
            bottom: -22px;
            left: 0;
            right: 0;
            height: 3px;
            background: ${colorPalette.white};
        }
    `}
`;

export const ComingSoonBadge = styled.span`
  margin-left: 4px;
  text-align: start;
  font-weight: 500;
  line-height: 7.2px;
  font-size: 8px;
  color: ${colorPalette.blue300};
`;

export const LoginButton = styled.button`
  background: ${colorPalette.white};
  color: ${colorPalette.blue500};
  border: transparent;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  ${typeScale.bodyXSMain}

  &:hover {
    opacity: 0.9;
  }
`;
